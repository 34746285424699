<template>
  <!-- 修改工资密码 -->
  <div class="changePassword">
    <van-nav-bar
      :title="$t('changeSalaryPsw.sapsTxt1')"
      left-text
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
    </van-nav-bar>
    <div>
      <div
        v-if="firstchangepwd == true || firstchangepwd == 'true'"
        class="first_hint"
      >
        {{ $t("changeSalaryPsw.sapsTxt7") }}
      </div>
      <!-- :rules="[{ required: true, message: '请填写原密码' }]" -->
      <van-form @submit="onSubmit">
        <!-- 启用工资查询密码启用数字+字母模式 -->
        <div v-if="showallkeyword">
          <van-field
            v-model="oldPassword"
            required
            clickable
            type="password"
            name="oldPassword"
            :label="$t('changeSalaryPsw.sapsTxt2')"
            :placeholder="$t('changeSalaryPsw.sapsTxt2')"
          />
          <van-field
            v-model="newPassword1"
            required
            clickable
            type="password"
            name="newPassword1"
            :maxlength="salary_pwd_count"
            :label="$t('changeSalaryPsw.sapsTxt3')"
            :placeholder="$t('changeSalaryPsw.sapsTxt3')"
          />
          <van-field
            v-model="newPassword2"
            required
            clickable
            type="password"
            name="newPassword2"
            :maxlength="salary_pwd_count"
            :label="$t('changeSalaryPsw.sapsTxt4')"
            :placeholder="$t('changeSalaryPsw.sapsTxt4')"
          />
        </div>
        <div v-else>
          <van-field
            v-model="oldPassword"
            required
            :readonly="true"
            clickable
            type="password"
            name="oldPassword"
            :label="$t('changeSalaryPsw.sapsTxt2')"
            :placeholder="$t('changeSalaryPsw.sapsTxt2')"
            @touchstart.native.stop="showBoard(1)"
          />
          <van-number-keyboard
            v-model="oldPassword"
            extra-key="X"
            :show="showBoard1"
            :maxlength="6"
            @blur="showBoard1 = false"
          />
          <van-field
            v-model="newPassword1"
            required
            :readonly="true"
            clickable
            type="password"
            name="newPassword1"
            :label="$t('changeSalaryPsw.sapsTxt3')"
            :placeholder="$t('changeSalaryPsw.sapsTxt3')"
            @touchstart.native.stop="showBoard(2)"
          />
          <van-number-keyboard
            v-model="newPassword1"
            extra-key="X"
            :show="showBoard2"
            :maxlength="6"
            @blur="showBoard2 = false"
          />
          <van-field
            v-model="newPassword2"
            required
            :readonly="true"
            clickable
            type="password"
            name="newPassword2"
            :label="$t('changeSalaryPsw.sapsTxt4')"
            :placeholder="$t('changeSalaryPsw.sapsTxt4')"
            @touchstart.native.stop="showBoard(3)"
          />
          <van-number-keyboard
            v-model="newPassword2"
            extra-key="X"
            :show="showBoard3"
            :maxlength="6"
            @blur="showBoard3 = false"
          />
        </div>

        <div style="margin: 16px">
          <van-button
            round
            block
            type="info"
            :disabled="!canSave"
            native-type="submit"
            >{{ $t("module.submit") }}</van-button
          >
        </div>
        <div class="forgetPwd">
          <span @click="getSms">{{ $t("changeSalaryPsw.sapsTxt5") }}</span>
        </div>
      </van-form>
    </div>
    <!-- 重置密码弹窗 -->
    <entSMS ref="entSMS" @beforeClose="beforeClose" />
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import { NumberKeyboard } from "vant";
import entSMS from "@components/entSMS";
import { changeSalaryPwd, setSalaryPwd, getSalarySignParam } from "@api/wx.js";
export default {
  components: {
    entSMS,
  },
  data() {
    let from = this.$route.query.from;
    let firstchangepwd = this.$route.query.firstchangepwd;
    let isfirstlogin = this.$route.query.isfirstlogin;
    let nosign = this.$route.query.nosign;
    return {
      firstchangepwd,
      isfirstlogin,
      nosign,
      from, // 判断是不是从工资密码验证页面而来
      userInfo: JSON.parse(localStorage.userInfo) || {},
      oldPassword: "",
      newPassword1: "",
      newPassword2: "",
      showBoard1: false,
      showBoard2: false,
      showBoard3: false,
      showallkeyword: false,
      salary_pwd_count: 6, // 密码位数
      use_salary_pwd_mix: false, // 工资查询密码启用数字+字母模式，
      not_fix_pwd_length: false, // 不固定密码位数(勾选后表示密码位数可以小于等于指定的限制位数)
    };
  },
  computed: {
    canSave() {
      if (
        this.oldPassword &&
        this.newPassword1 &&
        this.newPassword2 &&
        this.newPassword1 == this.newPassword2
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.getSalarySetting();
  },
  methods: {
    // 获取薪资参数
    getSalarySetting() {
      getSalarySignParam({
        empcode: this.userInfo.empcode,
      }).then((res) => {
        this.salary_pwd_count = res.data[0].salary_pwd_count; // 密码位数
        this.use_salary_pwd_mix = res.data[0].use_salary_pwd_mix; // 工资查询密码启用数字+字母模式，
        this.not_fix_pwd_length = res.data[0].not_fix_pwd_length; // 不固定密码位数
        if (this.use_salary_pwd_mix) {
          this.showallkeyword = true;
        }
      });
    },
    showBoard(val) {
      if (val == 1) {
        this.showBoard1 = true;
        this.showBoard2 = false;
        this.showBoard3 = false;
      } else if (val == 2) {
        this.showBoard1 = false;
        this.showBoard2 = true;
        this.showBoard3 = false;
      } else if (val == 3) {
        this.showBoard1 = false;
        this.showBoard2 = false;
        this.showBoard3 = true;
      }
    },
    onClickLeft() {
      //  this.$router.push('/personCen')
      this.$router.back();
    },
    onClickRight() {},
    // 提交
    onSubmit(values) {
      if (this.use_salary_pwd_mix && !this.not_fix_pwd_length) {
        if (values.newPassword1.length != this.salary_pwd_count) {
          Toast.fail("工资密码限制密码位数为：" + this.salary_pwd_count);
          return false;
        }
      }
      const data = {
        empcode: this.userInfo.empcode,
        oldpwd: values.oldPassword,
        newpwd1: values.newPassword1,
        newpwd2: values.newPassword2,
      };
      changeSalaryPwd(data).then((res) => {
        if (res.iserror === "0") {
          if (this.from == "checkSalaryPwd") {
            // 是从工资密码验证页面来的，改完就要回去
            Toast.success(this.$t("changeSalaryPsw.sapsTxt6"));
            this.$router.push({
              path: "/salarySiginPassword",
              query: {
                firstchangepwd: false,
                isfirstlogin: this.isfirstlogin,
                nosign: this.nosign,
              },
            });
          } else {
            Dialog.alert({
              message: this.$t("changeSalaryPsw.sapsTxt6"),
            }).then(() => {
              // on close
              this.onClickLeft();
            });
          }
        }
      });
    },
    // 忘记密码
    getSms() {
      this.$refs.entSMS.show = true;
    },
    // 验证码框关闭
    beforeClose(action) {
      if (action === "confirm") {
        // 验证码正确，重置页面
        this.$router.push("/resetSalaryPsw");
        this.$refs.entSMS.show = false;
        this.$refs.entSMS.s = 0;
        // this.$emit('beforeClose', action)
      } else {
        // 取消获取验证码
        // 重新登录
        // this.goLogin()
        this.$refs.entSMS.show = false;
        this.$refs.entSMS.s = 0;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.changePassword {
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
    .qj {
      color: #fff;
    }
  }
  .first_hint {
    font-size: 32px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    background-color: #ffffff;
    border-bottom: 0.02667rem solid #ebedf0;
  }
  .van-form {
    .pswDesc {
      padding: 20px 30px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #333333;
    }
    .forgetPwd {
      text-align: right;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #1989fa;
      span {
        padding: 20px;
      }
    }
  }
}
</style>